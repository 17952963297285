body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hide {
  display: none;
}
.MuiAutocomplete-input {
  padding: 1.5px 3px 1px 5px !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 9px 14px !important;
}